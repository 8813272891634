var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../../utils/axios';
export var getAllReceiptPayment = createAsyncThunk('receipt-payment/all', function () { return __awaiter(void 0, void 0, void 0, function () {
    var data, response, err_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 4]);
                return [4 /*yield*/, axios.get('/pdf-content')];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, response.data];
            case 2:
                data = _a.sent();
                if (response.status === 200) {
                    return [2 /*return*/, data.data];
                }
                throw new Error(response.statusText);
            case 3:
                err_1 = _a.sent();
                return [2 /*return*/, Promise.reject(err_1.message ? err_1.message : data === null || data === void 0 ? void 0 : data.message)];
            case 4: return [2 /*return*/];
        }
    });
}); });
export var getOneReceiptPayment = createAsyncThunk('receipt-payment/one', function (id) { return __awaiter(void 0, void 0, void 0, function () {
    var data, response, err_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 4]);
                return [4 /*yield*/, axios.get("/pdf-content/".concat(id))];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, response.data];
            case 2:
                data = _a.sent();
                if (response.status === 200) {
                    return [2 /*return*/, data.data];
                }
                throw new Error(response.statusText);
            case 3:
                err_2 = _a.sent();
                return [2 /*return*/, Promise.reject(err_2.message ? err_2.message : data === null || data === void 0 ? void 0 : data.message)];
            case 4: return [2 /*return*/];
        }
    });
}); });
export var editReceiptPayment = createAsyncThunk('receipt-payment/edit', function (_a) {
    var id = _a.id, body = _a.body;
    return __awaiter(void 0, void 0, void 0, function () {
        var data, response, err_3;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 3, , 4]);
                    return [4 /*yield*/, axios.patch("/pdf-content/".concat(id), body)];
                case 1:
                    response = _b.sent();
                    return [4 /*yield*/, response.data];
                case 2:
                    data = _b.sent();
                    if (response.status === 200) {
                        return [2 /*return*/, data];
                    }
                    throw new Error(response.statusText);
                case 3:
                    err_3 = _b.sent();
                    return [2 /*return*/, Promise.reject(err_3.message ? err_3.message : data === null || data === void 0 ? void 0 : data.message)];
                case 4: return [2 /*return*/];
            }
        });
    });
});
export var getReceiptPaymentByTitle = createAsyncThunk('receipt-payment/title', function (title) { return __awaiter(void 0, void 0, void 0, function () {
    var data, response, err_4;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 4]);
                return [4 /*yield*/, axios.get("/pdf-content/title/".concat(title))];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, response.data];
            case 2:
                data = _a.sent();
                if (response.status === 200) {
                    return [2 /*return*/, data.data];
                }
                throw new Error(response.statusText);
            case 3:
                err_4 = _a.sent();
                return [2 /*return*/, Promise.reject(err_4.message ? err_4.message : data === null || data === void 0 ? void 0 : data.message)];
            case 4: return [2 /*return*/];
        }
    });
}); });
export var generatePdf = createAsyncThunk('/receipt-payment/pdf', function (_a) {
    var content = _a.content;
    return __awaiter(void 0, void 0, void 0, function () {
        var data, response, err_5;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 3, , 4]);
                    return [4 /*yield*/, axios.post("/cash-desk-payments/financial-receipt-pdf", { content: content }, { responseType: 'blob' })];
                case 1:
                    response = _b.sent();
                    return [4 /*yield*/, response.data];
                case 2:
                    data = _b.sent();
                    if (response.status === 200) {
                        return [2 /*return*/, data];
                    }
                    throw new Error(response.statusText);
                case 3:
                    err_5 = _b.sent();
                    return [2 /*return*/, Promise.reject(err_5.message ? err_5.message : data === null || data === void 0 ? void 0 : data.message)];
                case 4: return [2 /*return*/];
            }
        });
    });
});
